import styled from 'styled-components';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';

const StyledAIDoctorChatSection = styled(PrimaryContainer)`
  .chat-heading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-title {
      margin-bottom: 24px;
    }
    .content-text {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .users-testimonials {
  }

  @media (max-width: 768px) {
    .chat-heading-content {
      align-items: flex-start;
      .content-title {
        margin-bottom: 16px;
      }
      .content-text {
        padding: 0;
        text-align: left;
      }
    }
  }
`;

export default StyledAIDoctorChatSection;
