'use client';

import React from 'react';
import { Col, Row } from 'antd';
import { BrowserView } from 'react-device-detect';
import MainImage from '@/components/global/MainImage';
import HipaaGdprSection from '@/components/common/sections/HipaaGdprSection';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import PrimaryGreenButton from '@/components/global/buttons/PrimaryGreenButton';
import PrivacyNoteSection from '@/components/common/sections/PrivacyNoteSection';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading1 from '@/components/global/typography/heading/Heading1/DarkHeading1';
import IntroAiDoctorSectionStyled from '@/components/pages/ai-doctor/IntroAiDoctorSection/style';
import PrimaryGreenText from '@/components/global/typography/text/PrimaryGreenText';
import { SIGN_UP_URL } from '@/utils/config';
import TrustPilotSection from '@/components/common/sections/TrustPilotSection';

const IntroAiDoctorSection = () => {
  return (
    <PrimaryContainer>
      <IntroAiDoctorSectionStyled>
        <Row className={'intro-content'}>
          <Col xs={24} md={10}>
            <DarkHeading1>
              <PrimaryGreenText>AI Doctor </PrimaryGreenText> for all your
              health questions
            </DarkHeading1>
            <MainParagraph className={'intro-text'}>
              Docus AI Doctor can be your first step toward an accurate
              diagnosis. Get recommendations, validate with top doctors, and
              stay healthy.
            </MainParagraph>
            <Row gutter={[0, 40]}>
              <Col xs={24}>
                <PrimaryGreenButton href={SIGN_UP_URL}>
                  Try Docus for Free
                </PrimaryGreenButton>
              </Col>
              <Col xs={24} md={{ flex: 'none' }}>
                <TrustPilotSection />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={{ offset: 1, span: 13 }}>
            <BrowserView>
              <MainImage
                loading={'eager'}
                src={'/ai-doctor/intro.png'}
                alt={'AI Doctor for all your health questions'}
              />
            </BrowserView>
          </Col>
        </Row>
        <Row gutter={[0, 24]} justify={'center'}>
          <Col xs={24} md={17}>
            <PrivacyNoteSection page={'ai-doctor'} />
          </Col>
          <Col xs={24}>
            <HipaaGdprSection />
          </Col>
        </Row>
      </IntroAiDoctorSectionStyled>
    </PrimaryContainer>
  );
};

export default IntroAiDoctorSection;
