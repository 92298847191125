import styled from 'styled-components';

interface IBringThePowerSingleItemStyled {
  $reversed: boolean;
}

const StyledAIDoctorAdvancedTextImageSectionCard = styled.div<IBringThePowerSingleItemStyled>`
  &&& {
    display: flex;
    flex-direction: ${(props) => (props.$reversed ? 'row-reverse' : 'row')};
    padding: 80px 40px;
    border-bottom: 1px solid #dfdfe2;
    .text-content-container {
      max-width: 500px;
      width: 100%;
      margin-right: ${(props) => (props.$reversed ? '0' : '80px')};
      .content-title {
        margin-bottom: 24px;
      }
      .content-text {
        margin-bottom: 16px;
      }
      .text-content-list {
        margin-bottom: 16px;
        .text-content-list-item {
          display: flex;
        }
      }
      .sub-content {
        margin-bottom: 40px;
        .text-info-content {
          .text-info-list {
            .text-info-item {
              margin-bottom: 16px;
              height: 100%;
              .text-info-number {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .text-info-img-item-wrapper {
                  display: flex;
                  align-items: flex-start;
                  height: 100%;
                  width: 8px;
                  margin-right: 12px;

                  .text-info-img-item {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 8px;
                    height: 8px;
                    background-color: #1a847c;
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .note-text {
            margin-top: 16px;
            font-size: 18px;
            line-height: 28px;
            font-style: italic;
            color: #272a3d;
          }
        }
      }

      .assessment-btn-wrapper {
        .assessment-btn {
          width: fit-content;
        }
      }
    }
    .image-container {
      display: flex;
      align-items: center;
      height: auto;
      .img-wrapper {
        position: relative;
        width: 480px;
        height: auto;
      }
    }
    @media (max-width: 768px) {
      padding: 40px 0px;
      flex-direction: column;
      .text-content-container {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        .content-title {
          margin-bottom: 16px;
        }
        .content-text {
          margin-bottom: 16px;
        }
        .text-content-list {
          .text-content-list-item {
          }
        }
        .sub-content {
          margin-bottom: 40px;
          .text-info-content {
            max-width: 100%;
            margin-right: 0;
            .content-text {
              margin-bottom: 40px;
            }
            .text-info-list {
              .text-info-item {
                .text-info-number {
                  display: flex;
                  align-items: flex-start;
                  .text-info-img-item-wrapper {
                    margin-top: 8px;
                    .text-info-img-item {
                    }
                  }
                }
              }
            }
          }
        }
        .assessment-btn-wrapper {
          .assessment-btn {
            width: 100%;
          }
        }
      }
      .image-container {
        margin-right: 0;
        .img-wrapper {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .note-text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export default StyledAIDoctorAdvancedTextImageSectionCard;
