import styled from 'styled-components';

const IntroAiDoctorSectionStyled = styled.div`
  .intro-content {
    margin-bottom: 40px;
  }
  .intro-text {
    margin: 24px 0 40px !important;
  }

  p {
    //margin: 40px 0 !important;
  }

  @media (max-width: 768px) {
    .intro-content {
      margin-bottom: 24px;
    }
  }
`;

export default IntroAiDoctorSectionStyled;
