import React, { ReactNode } from 'react';
import MainImage from '@/components/global/MainImage';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading3 from '@/components/global/typography/heading/Heading3/DarkHeading3';
import DarkHeading4 from '@/components/global/typography/heading/Heading4/DarkHeading4';
import PrimaryGreenButton from '@/components/global/buttons/PrimaryGreenButton';
import PrimaryGreenButtonLink from '@/components/global/buttons/PrimaryGreenButtonLink';
import { Col, Row } from 'antd';
import StyledAIDoctorAdvancedTextImageSectionCard from '@/components/pages/ai-doctor/AIDoctorAdvancedTextImageSection/AIDoctorAdvancedTextImageSectionCard/style';

interface ITextListItem {
  icon: ReactNode;
  text: ReactNode;
}

interface ISubContentItems {
  id: number;
  number: string;
  title: string;
  text: string;
}

interface IBringThePowerSingleItemProps {
  title: string;
  description: string;
  reversed?: boolean;
  hasSubContent?: boolean;
  subContentText?: string;
  textsList?: ITextListItem[];
  subContentItems?: ISubContentItems[];
  imageUrl: string;
  imageAlt: string;
  sectionButton?: ReactNode;
  buttonText: string;
  linkUrl?: string;
  buttonAction?: React.MouseEventHandler;
  noteText?: string;
}

const AIDoctorAdvancedTextImageSectionCard = ({
  title,
  imageUrl,
  description,
  reversed = false,
  subContentText,
  subContentItems,
  textsList,
  imageAlt,
  hasSubContent,
  buttonAction,
  linkUrl,
  buttonText,
  noteText,
}: IBringThePowerSingleItemProps) => {
  return (
    <StyledAIDoctorAdvancedTextImageSectionCard $reversed={reversed}>
      <Row gutter={[0, 40]} align={'middle'} className={'w-full'}>
        <Col
          xs={{ span: 24, order: 2 }}
          md={{
            span: reversed ? 10 : 12,
            offset: reversed ? 2 : 0,
            order: reversed ? 2 : 1,
          }}
        >
          <div className={'text-content-container'}>
            <DarkHeading3 className={'content-title'}>{title}</DarkHeading3>
            <MainParagraph className={'content-text whitespace-pre-line'}>
              {description}
            </MainParagraph>
            {hasSubContent && (
              <div className={'sub-content'}>
                {subContentText && (
                  <MainParagraph
                    className={'sub-content-text whitespace-pre-line mb-4'}
                  >
                    {subContentText}
                  </MainParagraph>
                )}
                <div className={'text-info-content'}>
                  <div className={'text-info-list'}>
                    {subContentItems &&
                      subContentItems.length > 0 &&
                      subContentItems.map((subContentItem) => {
                        return (
                          <div
                            className={'text-info-item'}
                            key={subContentItem.number}
                          >
                            <div className={'text-info-number'}>
                              <div className={'text-info-img-item-wrapper'}>
                                <div className={'text-info-img-item'} />
                              </div>
                              <DarkHeading4 className={'mb-0'}>
                                {subContentItem.title}
                              </DarkHeading4>
                            </div>
                            <MainParagraph className={'mb-0 ml-1 pl-4'}>
                              {subContentItem.text}
                            </MainParagraph>
                          </div>
                        );
                      })}
                  </div>
                  {noteText && (
                    <div className={'note-text mt-4'}>{noteText}</div>
                  )}
                </div>
              </div>
            )}

            {buttonText && (
              <div className={'assessment-btn-wrapper'}>
                {linkUrl ? (
                  <PrimaryGreenButtonLink
                    className={'assessment-btn'}
                    onClick={buttonAction}
                    href={linkUrl}
                  >
                    {buttonText}
                  </PrimaryGreenButtonLink>
                ) : (
                  <PrimaryGreenButton
                    onClick={buttonAction}
                    className={'assessment-btn'}
                  >
                    {buttonText}
                  </PrimaryGreenButton>
                )}
              </div>
            )}
          </div>
        </Col>

        <Col
          xs={{ span: 24, order: 1 }}
          md={{
            span: reversed ? 12 : 10,
            offset: reversed ? 0 : 2,
            order: reversed ? 1 : 2,
          }}
        >
          <div className={'image-container'}>
            <div className={'img-wrapper'}>
              <MainImage src={imageUrl} alt={imageAlt} />
            </div>
          </div>
        </Col>
      </Row>
    </StyledAIDoctorAdvancedTextImageSectionCard>
  );
};

export default AIDoctorAdvancedTextImageSectionCard;
