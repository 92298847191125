'use client';

import React from 'react';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import { advancedTextImageSectionData } from '@/data/static/ts/advancedTextImageSectionData';
import StyledAIDoctorAdvancedTextImageSection from '@/components/pages/ai-doctor/AIDoctorAdvancedTextImageSection/style';
import AIDoctorAdvancedTextImageSectionCard from '@/components/pages/ai-doctor/AIDoctorAdvancedTextImageSection/AIDoctorAdvancedTextImageSectionCard';

const AIDoctorAdvancedTextImageSection = () => {
  const { title, sectionData } = advancedTextImageSectionData['ai-doctor'];

  return (
    <PrimaryContainer>
      <StyledAIDoctorAdvancedTextImageSection>
        <DarkHeading2 className={'section-title'}>{title}</DarkHeading2>
        <div className={'cards-wrapper'}>
          {sectionData.map((sectionItem) => {
            return (
              <AIDoctorAdvancedTextImageSectionCard
                key={sectionItem.title}
                {...sectionItem}
              />
            );
          })}
        </div>
      </StyledAIDoctorAdvancedTextImageSection>
    </PrimaryContainer>
  );
};

export default AIDoctorAdvancedTextImageSection;
