import styled from 'styled-components';

const StyledAIDoctorAdvancedTextImageSection = styled.div`
  &&& {
    .section-title {
      text-align: center;
    }

    .cards-wrapper {
      & > div {
        .text-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
    @media (max-width: 768px) {
      .section-title {
        text-align: left;
      }
    }
  }
`;

export default StyledAIDoctorAdvancedTextImageSection;
