'use client';

import React from 'react';
import { Col, Row } from 'antd';
import PrivacyNoteSection from '@/components/common/sections/PrivacyNoteSection';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import StyledAIDoctorChatSection from '@/components/pages/ai-doctor/AIDoctorChatSection/style';
import DemoComposer from '@/components/pages/ai-health-assistant/ChatSection/DemoChat/DemoComposer';
import DemoChatSectionStyled from '@/components/pages/ai-health-assistant/ChatSection/DemoChat/style';

const AIDoctorChatSection = () => {
  return (
    <StyledAIDoctorChatSection>
      <div className={'chat-heading-content'}>
        <DarkHeading2 className={'content-title'}>
          Experience AI Doctor in Action
        </DarkHeading2>
        <MainParagraph className={'content-text'}>
          Chat directly. Ask any health-related question and see real-time
          AI-driven responses.
        </MainParagraph>
      </div>
      <DemoChatSectionStyled>
        <div className={'composer-container'}>
          <DemoComposer />
        </div>
        <Row gutter={[0, 24]} justify={'center'}>
          <Col xs={24} md={17}>
            <PrivacyNoteSection page={'ai-doctor/chat'} />
          </Col>
        </Row>
      </DemoChatSectionStyled>
    </StyledAIDoctorChatSection>
  );
};

export default AIDoctorChatSection;
